<template>
  <div class="d-flex justify-space-between mb-2 pa-2 elevation-1">
    <div>
      <div class="red--text overline">Admin Zone</div>
      <div>Verify Status: <span>{{ publication.verified ? 'Verified!' : 'Not Verified'}}</span></div>
      <div>Included in FPR: <span>{{ publication.includedInFpr ? 'Yes' : 'No'}}</span></div>
    </div>
    <div class="d-flex flex-column-reverse">
      <div>
        <se2a-submit-dialog v-if="publication.workline === 'start'" title="data ready for review" @onSubmit="readyReview">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" class="ml-2" dark v-bind="attrs" v-on="on">
              Data Ready for Review
            </v-btn>
          </template>

          <template v-slot:text>
            Do you really want to mark this as ready for a review?
          </template>
        </se2a-submit-dialog>

        <se2a-submit-dialog v-if="publication.workline !== 'unclear' && publication.workline !== 'finished'" title="data is unclear" @onSubmit="readyUnclear">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" class="ml-2" dark v-bind="attrs" v-on="on">
              Data is unclear
            </v-btn>
          </template>

          <template v-slot:text>
            Do you really want to mark this as unclear?
          </template>
        </se2a-submit-dialog>

        <se2a-submit-dialog v-if="!publication.verified" title="Verify Publication" @onSubmit="verifyPublication">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" class="ml-2" dark v-bind="attrs" v-on="on">
              Verify
            </v-btn>
          </template>

          <template v-slot:text>
            Do you really want to verify this publication?
          </template>
        </se2a-submit-dialog>

        <se2a-submit-dialog v-if="!publication.includedInFpr" title="Included in Final Project Report" @onSubmit="includeFpr">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" class="ml-2" dark v-bind="attrs" v-on="on">
              Included in FPR
            </v-btn>
          </template>

          <template v-slot:text>
            Do you really want to mark this publication as included in a FPR?
          </template>
        </se2a-submit-dialog>

        <se2a-submit-dialog title="Delete Publication?" @onSubmit="deletePublication">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="red" class="ml-2" dark v-bind="attrs" v-on="on">
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </template>

          <template v-slot:text>
            Do you really want to delete this publication?
          </template>
        </se2a-submit-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import {useRouter} from "vue2-helpers/vue-router";
import {
  deletePublicationByObject, includedInFprPublicationByObject, setWorklineByPublicationObject,
  verifyPublicationByObject
} from "@/modules/publication-next/api/publication";
import Se2aSubmitDialog from "@/components/se2a-submit-dialog/se2a-submit-dialog";

export default {
  name: "se2a-publication-admin-zone",
  components: {Se2aSubmitDialog},
  props: {
    publication: Object
  },
  setup(props) {

    const router = useRouter();
    const deletePublication = () => {
      deletePublicationByObject(props.publication).then(() => {
        router.push({name: 'publication-list'});
      })
    }

    const verifyPublication = () => {
      Promise.all([
        setWorklineByPublicationObject(props.publication, 'finished'),
        verifyPublicationByObject(props.publication)
      ]).then(result => {
        props.publication.verified = true;
        props.publication.workline = 'finished';
      })
    }

    const includeFpr = () => {
      includedInFprPublicationByObject(props.publication).then(result => {
        props.publication.includedInFpr = true;
      }).catch(reason => {
        //TODO: Handle this
      })
    }

    const readyReview = () => {
      setWorklineByPublicationObject(props.publication, 'review').then(result => {
        props.publication.workline = 'review';
      })
    }

    const readyUnclear = () => {
      setWorklineByPublicationObject(props.publication, 'unclear').then(result => {
        props.publication.workline = 'unclear';
      })
    }

    return {
      deletePublication,
      verifyPublication,
      includeFpr,
      readyReview,
      readyUnclear
    }
  }
}
</script>

<style scoped>

</style>
