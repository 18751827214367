<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="700"
  >
    <template v-slot:activator="props">
      <slot name="activator" v-bind="props"></slot>
    </template>

    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-text>
        <slot name="text" />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="darken-1" text @click="clickSubmit">
          Yes
        </v-btn>
        <v-btn color="darken-1" text @click="show = false">
          No
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {ref} from "vue";

export default {
  name: "se2a-submit-dialog",
  props: {
    title: String
  },
  emits: ['onSubmit'],
  setup(props, { emit }) {
    const show = ref(false);

    const clickSubmit = () => {
      show.value = false;
      emit('onSubmit');
    }

    return {
      show,
      clickSubmit
    }
  }
}
</script>
