<template>
  <v-card>
    <v-card-text>
      <div v-if="loading || error">
        <div v-if="loading">
          Loading..
        </div>
        <div v-else>
          Error while loading Goals
        </div>
      </div>
      <div v-else>

        <div v-if="!editMode">
          <v-simple-table v-if="goals.length > 0">
            <thead>
            <tr>
              <th class="text-left">
                Unit
              </th>
              <th class="text-left">
                Goal Target
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="goal in goals" :key="goal.id">
              <td>{{ goal.unit.shortName }}</td>
              <td>{{ goal.goal.displayName }}</td>
            </tr>
            </tbody>
          </v-simple-table>
          <div v-else>
            No Publication goals defined
          </div>
        </div>
        <div v-else>

          <v-simple-table v-if="goals.length > 0">
            <thead>
            <tr>
              <th class="text-left">
                Unit
              </th>
              <th class="text-left">
                Goal Target
              </th>
              <th>
                Actions
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="goal in goals" :key="goal.id">
              <td>
                {{ goal.unit.shortName }}
              </td>
              <td>
                <v-select
                  v-model="goal.goal"
                  item-text="displayName"
                  return-object
                  :items="allGoals"
                  @change="updateGoal(goal)"
                />
              </td>
              <td>
                <v-btn text @click="removeGoal(goal)"><v-icon>mdi-close</v-icon></v-btn>
              </td>
            </tr>
            </tbody>
          </v-simple-table>

          <v-divider />

          <div class="pt-4 d-flex" style="gap: 4px;">
            <v-select
              v-model="newGoal.unit"
              item-text="customName"
              return-object
              :items="unitsWithProjectPhase"
              dense
              hide-details
            />
            <v-select
              v-model="newGoal.goal"
              item-text="displayName"
              :items="allGoals"
              return-object
              dense
              hide-details
            />
            <v-btn small :disabled="!newGoalValid" @click="appendNewGoal">Add Goal</v-btn>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import {computed, onMounted, reactive, ref, unref} from "vue";
import {
  getGoalsByPublication,
  getPublicationGoals
} from "@/modules/publication-next/api/publication";
import {useGetters} from "vuex-composition-helpers";
import {CLIENT} from "@/api/client";
import {byApi} from "@/api/helpers";

export default {
  name: "se2a-publication-detail-goals",
  props: {
    publication: Object,
    editMode: Boolean
  },
  setup(props) {

    const allGoals = ref([]);
    const goals = ref([]);
    const loading = ref(false);
    const error = ref(false);
    onMounted(() => {
      getGoalsByPublication(props.publication).then(result => {
        goals.value = result.asPaginationResult().membersData();
      }).catch(() => {
        error.value = true;
      }).finally(() => {
        loading.value = false;
      })

      getPublicationGoals().then(result => {
        allGoals.value = result.asPaginationResult().membersData();
      })
    })

    const removeGoal = (goal) => {
      props.publication.goals = props.publication.goals.filter(item => item !== goal['@id'])
      goals.value = goals.value.filter(check => check !== goal);
    };

    const { allUnits } = useGetters('unit', {allUnits: 'items'});
    const unitsWithProjectPhase = computed(() => {
      return allUnits.value.map(u => {
        return {
          ...u,
          customName: u.projectPhase == null ? u.shortName : `${u.projectPhase}: ${u.shortName}`
        }
      });
    });

    const newGoal = ref({});
    const newGoalValid = computed(() => {
      return newGoal.value.hasOwnProperty('unit') && newGoal.value.hasOwnProperty('goal');
    });

    const updateGoal = (goal) => {
      props.publication.goals = props.publication.goals.filter(item => item !== goal['@id'])
      CLIENT.go(byApi('publication_unit_goal_targets'))
        .addModifier('unit.id', goal.unit.id)
        .addModifier('goal.id', goal.goal.id)
        .get().then(result => {
          let iri = result.asPaginationResult().membersData()[0]['@id'];
          props.publication.goals.push(iri);
          goal['@id'] = iri;
        }
      );
    }

    const appendNewGoal = () => {
      CLIENT.go(byApi('publication_unit_goal_targets'))
        .addModifier('unit.id', newGoal.value.unit.id)
        .addModifier('goal.id', newGoal.value.goal.id)
        .get().then(result => {
          let iri = result.asPaginationResult().membersData()[0]['@id'];
          props.publication.goals.push(iri);
          newGoal.value['@id'] = iri;
          goals.value.push(unref(newGoal));
          newGoal.value = {};
        }
      );
    };

    return {
      goals,
      updateGoal,
      allGoals,
      loading,
      error,
      newGoal,
      allUnits,
      unitsWithProjectPhase,
      newGoalValid,
      appendNewGoal,
      removeGoal
    }
  }
}
</script>

<style scoped>

</style>
