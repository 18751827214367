<template>

  <v-container v-if="!isLoading" fluid>
    <se2a-publication-admin-zone v-if="isAdmin" :publication="publication" />

    <div class="d-flex justify-space-between pb-3">
      <div v-if="!editMode" class="text-h5 text-nowrap text-truncate">

        {{ publication.title }}

      </div>
      <div class="ml-auto" v-if="!editMode">
        <v-tooltip v-if="publication.workline === 'unclear' && !publication.verified" color="red" bottom>
          <template #activator="{ on }">
            <v-chip v-on="on" color="red"> Data is unclear </v-chip>
          </template>

          <span>The data is not presented publicly on the SE²A website, which means that basic information could be missing</span>
        </v-tooltip>
        <v-tooltip bottom v-if="!publication.verified && publication.workline !== 'unclear'">
          <template #activator="{ on }">
            <v-chip v-on="on" color="orange"> Pending </v-chip>
          </template>

          <span>The publication will soon appear on the SE²A website.</span>
        </v-tooltip>
        <v-tooltip bottom v-if="publication.verified">
          <template #activator="{ on }">
            <v-chip v-on="on" color="green"> Data presented </v-chip>
          </template>

          <span>The data is presented publicly on the SE²A website</span>
        </v-tooltip>



      </div>
      <v-text-field style="padding-top: 0;" v-else v-model="publication.title" placeholder="Title"/>
      <div v-if="canEdit" class="pl-2">
        <v-btn v-if="!editMode" @click="editMode = true">Edit Publication</v-btn>
        <v-btn v-else @click="savePublication" color="green">Save Publication</v-btn>
        <v-btn v-if="editMode && publication.doi" :loading="importLoading" class="ml-2" @click="importPublicationAction" color="yellow">Import from DOI</v-btn>
      </div>
    </div>

    <v-row>
      <v-col cols="8">
        <v-card class="mx-auto">
          <v-card-text>


            <div v-if="!editMode" class="mt-3">
              <h4>Fields:</h4>
              <v-simple-table>
                <tbody>
                <tr v-for="(value, field) in publication.fields" v-bind:key="field">
                  <td>{{ field }}</td>
                  <td>{{ value }}</td>
                </tr>
                </tbody>
              </v-simple-table>
            </div>

            <div v-else>
              <se2a-publication-field-editor
                title="Required Fields"
                :fields="activePublicationType.requiredFields"
                :model="publication.fields"
              />

              <se2a-publication-field-editor
                title="Optional Fields"
                :fields="activePublicationType.optionalFields"
                :model="publication.fields"
              />
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="4">
        <v-card class="mx-auto">
          <v-card-text>
            <div v-if="!editMode">
              <h3>Metadata:</h3>
              <v-simple-table>
                <tbody>
                <tr>
                  <td>DOI</td>
                  <td><v-chip>{{ publication.doi }}</v-chip></td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td><v-chip>{{ publication.metadata.status }}</v-chip></td>
                </tr>
                <tr>
                  <td>Type</td>
                  <td><v-chip>{{ publication.type.displayName }}</v-chip></td>
                </tr>

                <tr>
                  <td>Peer reviewed?</td>
                  <td><v-chip>{{ publication.metadata['is-peer-reviewed'] ? 'Yes' : 'No' }}</v-chip></td>
                </tr>

                </tbody>
              </v-simple-table>
            </div>

            <div v-else>
              <v-text-field
                v-model="publication.doi"
                placeholder="DOI"
                outlined
              />

              <v-select
                v-model="publication.metadata['status']"
                placeholder="Status"
                :items="publicationStatuses"
                item-text="name"
                item-value="value"
                outlined
              />

              <v-select
                v-model="publication.type"
                placeholder="Type"
                :items="publicationTypes"
                item-text="displayName"
                return-object
                outlined
              />

              <v-checkbox
                v-model="publication.metadata['is-peer-reviewed']"
                label="Is this publication peer reviewed?"
                outlined
              ></v-checkbox>
            </div>
          </v-card-text>
        </v-card>

        <!-- Spacer -->
        <div class="pt-6"/>

        <!-- Unit Goal Targets -->
        <se2a-publication-detail-goals :publication="publication" :edit-mode="editMode" />
      </v-col>

    </v-row>

    <v-snackbar v-model="publicationLoadError">
      Error while loading publication
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="publicationLoadError = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="successfullySaved">
      Successfully saved publication
      <template v-slot:action="{ attrs }">
        <v-btn
          color="green"
          text
          v-bind="attrs"
          @click="successfullySaved = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import {defineComponent, ref, watchEffect, inject, computed, onMounted} from "vue";
import {
  deletePublicationByObject,
  findPublication,
  getAllPublicationTypes, importPublication,
  saveNormalizedPublication
} from "@/modules/publication-next/api/publication";
import Se2aPublicationFieldEditor
  from "@/modules/publication-next/component/se2a-publication-field-editor/se2a-publication-field-editor";
import {useRouter} from "vue2-helpers/vue-router";
import {useGetters} from "vuex-composition-helpers";
import Se2aPublicationAdminZone
  from "@/modules/publication-next/component/se2a-publication-admin-zone/se2a-publication-admin-zone";
import Se2aPublicationDetailGoals
  from "@/modules/publication-next/page/se2a-publication-detail/se2a-publication-detail-goals";

export default defineComponent({
  name: "se2a-publication-detail",
  components: {Se2aPublicationDetailGoals, Se2aPublicationAdminZone, Se2aPublicationFieldEditor},
  props: {
    publicationId: Number|String
  },
  setup(props) {
    const isLoading = ref(true);

    const editMode = ref(false);
    const canEdit = ref(true);

    const publication = ref({});
    const publicationLoadError = ref(false);
    const fetchPublication = () => {
      isLoading.value = true;
      findPublication(props.publicationId).then(result => {
        publication.value = result.rawData;
      })
      .catch(reason => {
        console.log("Error while loading publication: ", reason);
        publicationLoadError.value = true;
      })
      .finally(() => isLoading.value = false);
    }

    const publicationTypes = ref([]);
    onMounted(() => {
      getAllPublicationTypes().then(result => {
        publicationTypes.value = result.asPaginationResult().membersData();
      }).catch(reason => {
        console.log("Error while fetching publication types");
      })
    });

    const activePublicationType = computed(() => {
      return publicationTypes.value.find(value => value['name'] === publication.value['type']['name']);
    });

    watchEffect(fetchPublication);

    const publicationStatuses = [
      {
        name: 'Planned',
        value: 'planned'
      },
      {
        name: 'Submitted',
        value: 'submitted'
      },
      {
        name: 'in Revision',
        value: 'in-revision'
      },
      {
        name: 'Accepted',
        value: 'accepted'
      },
      {
        name: 'Published',
        value: 'published'
      }
    ];

    const importLoading = ref(false);
    const importPublicationAction = () => {
      importLoading.value = true;
      importPublication(publication.value.doi).then(result => {
        publication.value.fields = result.rawData.data.fields;
        publication.value.title = result.rawData.data.title;
        publication.value.type = publicationTypes.value.find(value => value['name'] === result.rawData.data.type);
      }).catch(reason => {
        console.log("Error while importing publication", reason);
      }).finally(() => {
        importLoading.value = false;
      })
    }

    const successfullySaved = ref(false);
    const savePublication = () => {

      // remove empty fields
      Object.keys(publication.value.fields).forEach((k) => {
        if(publication.value.fields[k] === "") {
          delete publication.value.fields[k]
        }
      });

      // remove fields not viable for publication type
      const aggregatedFields = [
        ...activePublicationType.value.optionalFields,
        ...activePublicationType.value.requiredFields
      ].map(field => field.name);

      Object.keys(publication.value.fields).forEach((k) => {
        if(!aggregatedFields.includes(k)) {
          delete publication.value.fields[k];
        }
      });



      // Save this.
      editMode.value = false;
      saveNormalizedPublication(publication.value).then(() => {
        successfullySaved.value = true;

        fetchPublication();
      }).catch(reason => {
        console.log("Error while saving publication");
      })


    }

    const { isAdmin } = useGetters('auth', ['isAdmin']);

    return {
      isLoading,
      canEdit,
      editMode,
      publicationLoadError,
      publication,
      activePublicationType,
      publicationTypes,
      publicationStatuses,
      savePublication,
      successfullySaved,
      importPublicationAction,
      importLoading,
      isAdmin
    }
  }
});
</script>

<style scoped>

</style>
